import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export default memo(function Error404() {
  const userLanguage =
    typeof window !== 'undefined' ? window.navigator.language : 'it'

  let code: string
  let prefix: string
  let title: string
  let text: string

  switch (userLanguage) {
    case 'it-IT':
    case 'it':
      code = 'it-IT'
      prefix = ``
      title = `Pagina non trovata`
      text = `Spiacenti, non siamo riusciti a trovare questa pagina!`
      break
    default:
      code = 'en-US'
      prefix = `en`
      title = `Page Not Found`
      text = `Oops, we couldn't find this page!`
  }

  return (
    <Container>
      <Wrapper>
        <Logo
          src="/logo.svg"
          width="203"
          height="160"
          alt="Hotel Mayer & Splendid"
        />
        <Title>{title}</Title>
        <Text>{text}</Text>
        <CTA
          label={useVocabularyData('back-to-home', code)}
          URL={prefix ? `/${prefix}` : '/'}
          variant="invert"
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Logo = styled.img`
  margin-bottom: 2.5rem;
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.875rem;
  text-transform: uppercase;
`

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
`

const CTA = styled(Button)`
  margin-top: 3.75rem;
`
